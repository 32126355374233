import { render, staticRenderFns } from "./patentOASummaryReviewOpinions.vue?vue&type=template&id=810e4464&scoped=true"
import script from "./patentOASummaryReviewOpinions.vue?vue&type=script&lang=js"
export * from "./patentOASummaryReviewOpinions.vue?vue&type=script&lang=js"
import style0 from "./patentOASummaryReviewOpinions.vue?vue&type=style&index=0&id=810e4464&prod&lang=scss&scoped=true"
import style1 from "./patentOASummaryReviewOpinions.vue?vue&type=style&index=1&id=810e4464&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "810e4464",
  null
  
)

export default component.exports